import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const Loader = styled.div`
  width: 16px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #48bb78;
  display:inline-block;
  --_m: 
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: ${rotate} 1s infinite linear;
`;

const LoaderSymbol = ({ loading }) => {
  return (
    <>
      {loading ? <Loader /> : <></>}
    </>
  );
};

export default LoaderSymbol;
