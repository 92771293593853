import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "@clerk/clerk-react";
import { API_URL,PATH_SEND,CONFIG_TIME_CONVERSATION_WINDOW } from "./utils/constants";
import EmojiPicker from "./EmojiPicker";
import ConversationTemplate from "./ConversationTemplate";
import ConversationRemarketing from "./ConversationRemarketing";

const ConversationTextbox = ({ conversation, company, handlerUpdate, lastUserMessage, handleRefreshConversations }) => {
  const [remainingTime, setRemainingTime] = useState(null);
  const [outMessage, setOutMessage] = useState("");
  const [countdown, setCountdown] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token
  const messageRef = useRef(null);

  const handleOnMessageChange = (event) => {
    setOutMessage(event.target.value);
  };

  const handleChangeMessage = (value) => {
    setOutMessage(value);
  };

  const handlePickEmoji= (emoji) => {
    setOutMessage((prevValue) => prevValue + emoji);
    console.log(emoji);
  };

  const handleSendMessage = async () => {
    setIsLoading(true);
    setError(null);
    console.log('Message:', outMessage);

    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation.contact?.company}/${conversation.contact?.contactid}/${PATH_SEND}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({message:outMessage})
      });
      if(response.status === 200){
        setOutMessage("");
        console.log("Message Sent "+conversation.contact?.contactid);
        handlerUpdate();
        handleRefreshConversations();
      }
      else{
        console.log("Error: "+response.data);
      }
    } catch (error) {
      console.error('Error:', error);
      setError("Error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };


  useEffect(() => {
    if (!lastUserMessage) return; // No need to run if there's no last message
  
    const now = new Date().getTime();
    const timeSinceLastMessage = now - lastUserMessage.getTime();
    let remainingTime = CONFIG_TIME_CONVERSATION_WINDOW - timeSinceLastMessage;
  
    if (remainingTime <= 0) {
      setRemainingTime(0);
      setCountdown('00:00');
      return;
    }
  
    setRemainingTime(remainingTime);
    updateCountdown(remainingTime);
  
    const interval = setInterval(() => {
      setRemainingTime(prevRemainingTime => {
        const updatedRemainingTime = prevRemainingTime - 30 * 1000;
  
        if (updatedRemainingTime <= 0) {
          clearInterval(interval);
          setCountdown('00:00');
          return 0;
        }
  
        updateCountdown(updatedRemainingTime);
        return updatedRemainingTime;
      });
    }, 30 * 1000); // 30 seconds interval
  
    return () => clearInterval(interval); // Cleanup on unmount
  
  }, [lastUserMessage]);
  
  // Helper function to update the countdown
  const updateCountdown = (remainingTime) => {
    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    setCountdown(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
    //console.log(remainingTime);
  };

  //Effect to update textarea size
  useEffect(() => {
    if (messageRef.current) {
      // Reset the height before calculating the new height
      messageRef.current.style.height = 'auto';
      const newHeight = messageRef.current.scrollHeight;
      const MAX_HEIGHT = 60;

      // Set the height according to the scroll height (content size)
      if (newHeight > MAX_HEIGHT) {
        messageRef.current.style.height = `${MAX_HEIGHT}px`;
        messageRef.current.style.overflowY = 'scroll';  // Enable scroll bar when max height is exceeded
      } else {
        messageRef.current.style.height = `${newHeight}px`;
        messageRef.current.style.overflowY = 'hidden';  // Hide scroll bar when within the limit
      }
    }
  }, [outMessage]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (remainingTime>0) {
    return (
        <div className="flex flex-row flex-shrink-0 items-center h-16 rounded-b-xl bg-white w-full p-2 sm:p-4 shadow z-50 bottom-0" id="send-message">

            <div className="flex-grow ml-4">
              <div className="relative w-full">
                <textarea
                  className="flex text-sm w-full border rounded-xl focus:outline-none px-3 py-2 h-10 shadow-inner resize-none"
                  name="message"
                  id="message"
                  ref={messageRef}
                  placeholder={remainingTime > 0 ? countdown : '00:00'}
                  value={outMessage}
                  onChange={handleOnMessageChange}
                  onKeyDown={handleEnterKey} // Detect Enter key press
                  disabled={isLoading || remainingTime <= 0}
                  rows="1"
                  style={{ minHeight: '40px', overflow: 'hidden' }}
                />
              </div>
            </div>

            {(remainingTime>0)?
            <>
              <div className="ml-4 hidden sm:block">
                <EmojiPicker handlePickEmoji={handlePickEmoji}/>
              </div>
              <div className="ml-4">
                <ConversationRemarketing conversation={conversation} handleChangeMessage={handleChangeMessage}/>
              </div>
            </>
            :<></>}

            <div className="ml-4">
              <button
                className={`flex items-center justify-center ${(isLoading)?'bg-gray-500':'bg-green-500'}  hover:bg-green-700 rounded-xl text-white px-4 py-1 flex-shrink-0`}
                type="submit"
                id="sendButton"
                onClick={handleSendMessage}
                disabled={isLoading}
              >
                <span className="hidden sm:inline mr-2">Enviar</span>
                <span className="py-2">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
        </div>
    );
  }
  else{
    return (
      <ConversationTemplate company={company} contactid={conversation?.contact?.contactid} handlerUpdate={handlerUpdate}/>
  );
  }
}

export default ConversationTextbox;
