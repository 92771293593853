// src/Menu.js
import React from "react";
import { Link } from "react-router-dom";
import { UserButton } from "@clerk/clerk-react";
import { STATIC_URL,APP_NAME } from "./utils/constants";

const Menu = () => {

  return (
    <div className="flex flex-column w-full px-3">
      <Link to={`/`} className="font-bold text-2xl justify-center flex-1 p-2"><img src={`${STATIC_URL}logo.png`} alt={APP_NAME}/></Link>
      <div className="flex-1"></div>
      <div className="flex-none flex flex-row align-middle">
        <div className="flex-grow"></div>
        <div className="flex-none pt-2">
          <UserButton className=""/>
        </div>
        <div className="flex-grow"></div>
      </div>
    </div>
  );
};

export default Menu;
