import React from 'react';
import { useChannel } from 'ably/react';

const AblyMessages = ({ company, contactid, handlerUpdateConversation, handlerUpdateConversations }) => {

    //console.log(`AblyChannel: ${company} ${contactid}`);

    const { channel }  = useChannel(company, (message) => {

        console.log('Received message:', message.name);
        if(message.name===contactid){
            //console.log('Update conversation');
            handlerUpdateConversation()
        }
        //console.log('Update conversations');
        handlerUpdateConversations();
    });

    return (<></>)
};

export default AblyMessages;
