import React, { useState,useRef,useEffect } from "react";
import { useAuth } from "@clerk/clerk-react";
import { API_URL,PATH_REMARKETING } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";

const ConversationRemarketing = ({conversation,handleChangeMessage}) => {
  const [showRemarketing, setShowRemarketing] = useState(false);
  const [showProbability, setShowProbability] = useState("");
  const { getToken } = useAuth(); // Get the token

  const handleRemarketing = async () => {
    setShowRemarketing(true);
    setShowProbability("");
    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation.contact?.company}/${conversation.contact?.contactid}/${PATH_REMARKETING}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();
      if(response.status === 200){
        console.log(data);
        setShowRemarketing(false);
        if(data?.message) handleChangeMessage(data.message);
        if(data?.probability) setShowProbability(''+ data.probability+'%')
        //if(data?.probability) window.alert('Probabilidad: '+ data.probability);
      }
      else{
        setShowRemarketing(false);
        console.log("Error: "+data);
        window.alert('Error: '+ data);
      }
    } catch (error) {
      setShowRemarketing(false);
      console.error('Error:', error);
      window.alert('Error: '+ error);
    }
  };

  return (
    <div className="relative inline-block">
      <button onClick={handleRemarketing} className="hover:text-green-500  focus:outline-none focus:border-none">
      {showRemarketing?
        <LoaderSymbol loading={showRemarketing} />
        :
        <>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="24" width="24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline-block">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
          </svg>
          {showProbability?<span className="text-xs block">{showProbability}</span>:``}
        </>
      }
      </button>
    </div>
  );
};

export default ConversationRemarketing;
