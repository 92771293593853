import { marked } from 'marked';

/**
 * messageFormat
 * @param {*} str 
 * @returns 
 */
function messageFormat(str){
  
  try {
    str=marked(str);
    str=optimizeImg(str);
    str=styleList(str);
    str=replaceImgWithVideo(str);
    str=replaceImgWithAudio(str);
    str=replaceImgWithPdf(str);
    str=replaceImgWithContactCard(str);
    //console.log(str);
    return str;
  } catch (error) {
    console.error("messageFormat: "+error);
    return str;
  }
}

/**
 * messageFormatSummary
 * @param {*} str 
 * @returns 
 */
function messageFormatSummary(str){
  
  try {
    const maxLength = 400;
    const processedString = str.length > maxLength ? str.slice(0, maxLength) + '…': str;
    return processedString;
  } catch (error) {
    console.error("messageFormat: "+error);
    return str;
  }
}

/**
 * optimize img
 * @param {*} html 
 * @returns 
 */
function styleList(html) {
  html=html.replace(/<ol>/g, '<ol class="list-decimal py-2 px-3">');
  html=html.replace(/<ul>/g, '<ol class="list-disc py-2 px-3">');
  return html;
}

/**
 * optimize img
 * @param {*} html 
 * @returns 
 */
function optimizeImg(html) {
  return html.replace(/<img([^>]+)(?<!loading=["']?lazy["']?)(\s*?)>/gi, '<img$1 loading="lazy"$2>');
}

/**
 * replace image with video
 * @param {*} html 
 * @returns 
 */
function replaceImgWithVideo(html) {
  return html.replace(/<img\s+[^>]*src=["']([^"']+\.(mp4|heic))["'][^>]*>/g, (match, src) => {
    return `
<video controls  class="my-4">
  <source src="${src}" type="video/mp4">
  Your browser does not support the video tag.
</video>`;
  });
}


/**
 * replace image with audio
 * @param {*} html 
 * @returns 
 */
function replaceImgWithAudio(html) {
  return html.replace(/<img\s+[^>]*src=["']([^"']+\.(mp3|ogg|amr|3gp|aac|mpeg))["'][^>]*>/gi, (match, src) => {
    return `
<audio controls>
  <source src="${src}" type="audio/${src.split('.').pop()}">
  Your browser does not support the audio tag.
</audio>`;
  });
}

/**
 * replace image with pdf
 * @param {*} html 
 * @returns 
 */
function replaceImgWithPdf(html) {
  return html.replace(/<img\s+[^>]*src=["']([^"']+\.pdf)["'][^>]*>/gi, (match, src) => {
    return `
<embed src="${src}" type="application/pdf" width="100%" height="600px" class="my-4" loading="lazy">
  <a href="${src}">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" className="inline-block">
      <path d="M8.75 2.75a.75.75 0 0 0-1.5 0v5.69L5.03 6.22a.75.75 0 0 0-1.06 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-1.06-1.06L8.75 8.44V2.75Z" />
      <path d="M3.5 9.75a.75.75 0 0 0-1.5 0v1.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-1.5a.75.75 0 0 0-1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z" />
    </svg>
  </a>
</embed>`;
  });
}

function replaceImgWithContactCard(html) {
  return html.replace(/<img\s+[^>]*src=["']([^"']+\.(vcf|vcard))["'][^>]*>/gi, (match, src) => {
    return `<a href="${src}">🪪 Contact card<a>`;
  });
}

export  { messageFormat,messageFormatSummary};