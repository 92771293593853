// src/Companies.js
import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL,APP_NAME } from "./utils/constants";
import Menu from "./Menu";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the messages from the backend
    const fetchCompanies = async () => {
      try {
        const token = await getToken(); // Obtain the token
        const response = await fetch(`${API_URL}companies`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCompanies(data);


        //If only 1 company, redirect
        if(data.data.length===1) navigate(`/company/${data.data[0].url}`);

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    document.title = `${APP_NAME}`;
    fetchCompanies();
  }, [getToken,navigate]);

  if (loading) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">
          <Menu />
        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>Cargando...</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">
          <Menu />
        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>Error: {error}</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
    ;
  }

  return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">
          <Menu />
        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            {companies.data.map((company) => (
                <Link 
                to={`/company/${company.url}`} 
                title={`${company.name}`}
                className="flex-none text-lg font-semibold m-3 p-3 rounded-lg bg-white hover:bg-green-100 shadow rounded-xl
                w-full sm:w-1/2 lg:w-1/4"
                key={company.url}>
                  {company.name}
                </Link>
            ))}
          </div>
        </div>
        {/* /Companies */}

      </div>
  );
};

export default Companies;
