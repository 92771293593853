// src/Companies.js
import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";
import FacebookSDK from "./FacebookEmbeddedLogin";


const SettingsCompany = ({ company, handlerToggleMenu, handlerShowMenu, handlerHideMenu, showMenu }) => {
  const { companyUrl, templateid } = useParams(); //Param
  const [waba_id, setWaba_id] = useState();
  const [template, setTemplate] = useState();
  const [code, setCode] = useState();
  const [fields, setFields] = useState([]);
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token

  //Load template
  useEffect(() => {
    setError();
    setResult();
  }, [companyUrl, templateid]);

  //Update Page title
  useEffect(() => {
    let title = "Configuración";

    if (title) {
      document.title = `${title} - ${companyUrl}`;
    } else {
      document.title = `${companyUrl}`;
    }

  }, []);

  //Update Code
  useEffect(() => {
    let title = "Configuración";

    if (title) {
      document.title = `${title} - ${companyUrl}`;
    } else {
      document.title = `${companyUrl}`;
    }

  }, [code]);


  const handleWhatsAppSignup = async () => {
    // Launch Facebook login
    try {
      window.FB.login((response) => {
        if (response.authResponse) {
          const code = response.authResponse.code;
          console.log(JSON.stringify(response, null, 2));
          
          // No await needed here since we're not using the return value
          fbLoginCallback(code);
        }
        else if(response.error) {
          console.error(response?.error);
        }
      }, {
        config_id: '571651532025887', // configuration ID goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          setup: {},
          featureType: '',
          sessionInfoVersion: '2',
        }
      });
    }
    catch (error) {
      console.error("Error: " + error);
    }
  }

  /**
   * Login Callback
   * @param {*} code 
   */
  const fbLoginCallback = async (code) => {
    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}/settings/saveAccessToken`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          waba_id: waba_id,
          code: code,
          url: ""
        })
      });

      console.log("Token request: " + {
        waba_id: waba_id,
        code: code,
        url: ""
      });
      console.log("Token response: " + response.data);

    } catch (error) {
      console.error('Error:', error);
      setResult('Error:', error);
    }
  }

  useEffect(() => {
    const handleMessage = async (event) => {
      // Verify origin for security
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
      }

      try {
        const data = JSON.parse(event.data);

        try {
          const token = await getToken(); // Get the token
          let body=data;

          const response = await fetch(`${API_URL}company/${companyUrl}/settings/saveWABA`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
          });
    
          console.log("Guardado: "+response.data);
    
        } catch (error) {
          console.error('Error:', error);
          setResult('Error:', error);
        }
        
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          switch (data.event) {
            case 'FINISH':
              const { phone_number_id, waba_id } = data.data;
              console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);

              setWaba_id(phone_number_id);
              
              // You can add your success handling logic here
              break;

            case 'CANCEL':
              const { current_step } = data.data;
              console.warn("Cancel at ", current_step);
              // You can add your cancel handling logic here
              break;

            case 'ERROR':
              const { error_message } = data.data;
              console.error("error ", error_message);
              // You can add your error handling logic here
              break;

            default:
              break;
          }
        }

        // Update the session info state with formatted JSON
        console.log(JSON.stringify(data, null, 2));

      } catch (error) {
        console.log('Non JSON Responses', event.data);
      }
    };

    // Add event listener when component mounts
    window.addEventListener('message', handleMessage);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []); // Empty dependency array means this runs once on mount



  if (error) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Error: {error}</h4>
        </div>
      </div>
    );
    ;
  }

  if (!companyUrl) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Empresa Inválida</h4>
        </div>
      </div>
    );
    ;
  }

  return (

    <div className={`${showMenu ? `hidden` : `flex`} flex-col flex-auto grow h-full p-0 sm:p-6`}>
      <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">


        <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">

          <h2>Configuración de Whatsapp</h2>

          <FacebookSDK />

          {waba_id?
          <p>Línea conectada: ID {waba_id}</p>
          :<button
            onClick={handleWhatsAppSignup}
            style={{ backgroundColor: '#1877f2' }}
            className="mt-3 rounded px-6 h-10 text-white font-bold text-base cursor-pointer font-sans hover:bg-blue-600 transition-colors"
          >
            Login with Facebook
          </button>
          }

        </div>

      </div>
    </div>
  );
};

export default SettingsCompany;
