// src/Companies.js
import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useParams, Link } from "react-router-dom";
import { API_URL, CONFIG_DEFAULT_DAYS_BACK_CONVERSATIONS, PATH_TAGS } from "./utils/constants";
import { shortFormat, timeAgo } from "./utils/dateHelper";
import Menu from "./Menu";
import Template from "./Template";
import TemplateAdd from "./TemplateAdd";
import LoaderSymbol from "./icons/LoaderSymbol";


const Templates = () => {
  const { companyUrl, templateid, action } = useParams(); //Param
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token

  useEffect(() => {
    fetchCompany();
    fetchTemplates();
  }, [getToken, companyUrl]);


  // Fetch the templates
  const fetchTemplates = async () => {
    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}/templates`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTemplates(data);
    } catch (error) {
      setError(error.message);
    } finally {
      //setLoadingSearch(false);
    }
  };

  //Fetch the company
  const fetchCompany = async () => {
    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCompany(data);
    } catch (error) {
      //setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlerToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handlerShowMenu = () => {
    setShowMenu(true);
  };

  const handlerHideMenu = () => {
    setShowMenu(false);
  };


  if (loading) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">

        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>Cargando...</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">

        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>Error: {error}</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
    ;
  }

  if (!company) {
    return (
      <div className="flex flex-row h-full w-full overflow-x-hidden">

        {/* Sidebar */}
        <div className="hidden sm:flex w-64 flex-col flex-none pt-4 bg-white flex-shrink-0 h-full">

        </div>
        {/* /Sidebar */}

        {/* Companies */}
        <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
          <div className="flex flex-row flex-wrap rounded-xl bg-gray-100 p-4">
            <h4>No existe la empresa</h4>
          </div>
        </div>
        {/* /Companies */}

      </div>
    );
    ;
  }

  const currentDate = shortFormat(new Date(), company?.tz);

  return (

    <div className="flex flex-row h-full w-full overflow-x-hidden">

      {/* Sidebar */}
      <div className={`${showMenu?`flex w-full`:`hidden sm:flex w-64`} flex-col flex-none pt-4 bg-white flex-shrink-0 h-full`}>

        <Menu />

        <div className="flex flex-row items-center justify-center w-full mt-2 px-2">
          <div className="flex flex-col">
            <h2><Link to={`/company/${company.data?.url}`} >{company.data?.name}</Link></h2>
          </div>
        </div>

        <div className="flex flex-col mt-2 w-full px-2">
          <div className="flex flex-row items-center justify-between text-sm flex-none mt-2">
            <span className="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="12" height="16" className="inline-block mr-1">
                <path d="M10 3.75a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM17.25 4.5a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM5 3.75a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 .75.75ZM4.25 17a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM17.25 17a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM9 10a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1 0-1.5h5.5A.75.75 0 0 1 9 10ZM17.25 10.75a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM14 10a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM10 16.25a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
              </svg>
              Templates</span>
            <Link to={`/company/${company.data?.url}/templates/add`} className="bg-green-500 hover:bg-green-700 rounded text-white px-4 py-1 flex-shrink-0">+</Link>
            <span className="flex items-center justify-center text-green-500">
              {templates ? templates.length : 0}
            </span>
          </div>
          
          
        </div>

        {/* Templates */}
        
        <div className="flex flex-col space-y-1 mt-4 mx-0 h-full overflow-auto shadow-inner" id="conversationsList">

          {templates?.map((template) => (
            <Link key={template._id}
              to={`/company/${template.company}/template/${template.templateId}`}
              className={`flex flex-row items-center ${(template.templateId === templateid) ? "bg-green-500 hover:bg-green-700 text-white " : "hover:bg-green-100"} p-2`}
              title={template.templateName}>

              <div className="">

                <div className="text-sm font-semibold align-bottom">
                  {template.templateName ? <>{template.templateName} <small className={(template.templateId === templateid) ? `text-white hover:text-green-100` : `text-gray-500 hover:text-gray-700`}>{template.templateId}</small></> : template.templateId}
                </div>

                <div className={`pt-1 text-xs ${(template.templateId === templateid) ? `text-white` : `text-gray-500`} align-bottom`}>
                  {template.message}
                </div>

              </div>
            </Link>
          ))}

        </div>
  
        {/* /Templates */}

      </div>
      {/* /Sidebar */}

      {/* Template */}
      {
        action === "add"?
        <TemplateAdd fetchTemplates={fetchTemplates}/>
        :
        
        <>
        { templateid ? 
          <Template company={company} showMenu={showMenu} handlerToggleMenu={handlerToggleMenu} handlerShowMenu={handlerShowMenu} handlerHideMenu={handlerHideMenu}/>
          :<></>
        }
        </>
        
      }
      {/* /Template */}

    </div>
  );
};

export default Templates;
