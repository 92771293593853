// src/SignUp.js
import React from "react";
import { SignUp } from "@clerk/clerk-react";

const SignUpPage = () => (
  <div>
    <SignUp path="/sign-up" routing="path" />
  </div>
);

export default SignUpPage;
