// src/Companies.js
import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { API_URL } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";


const Conversation = ({company,handlerToggleMenu,handlerShowMenu,handlerHideMenu,showMenu}) => {
  const {companyUrl, templateid } = useParams(); //Param
  const [template, setTemplate] = useState();
  const [contactid, setContactid] = useState();
  const [fields, setFields] = useState([]);
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [error, setError] = useState(null);
  const { getToken } = useAuth(); // Get the token

  // Fetch the conversations
  const fetchTemplate = async () => {
    try {
      const token = await getToken(); // Obtain the token
      const response = await fetch(`${API_URL}company/${companyUrl}/template/${templateid}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTemplate(data);
      setPreview(data?.message?data.message:'')
    } catch (error) {
      setError(error.message);
    }
  };

  /**
   * Handle Send Template
   * @returns 
   */
  const handleSendTemplate = async () => {
    const resetConfirmation = window.confirm('Enviar mensaje?\n\n'+preview);
      if (!resetConfirmation) return;

    setLoading(true);

    try {
      const token = await getToken(); // Get the token
      let body={};
      fields?.map((value, index) => {
        if (index !== 0) {
          body[index] = value;
        }
      });
      const response = await fetch(`${API_URL}company/${companyUrl}/template/${templateid}/send/${contactid}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      console.log(body);

      if(response.status === 200){
        setResult("Mensaje enviado");
      }
      else{
        console.log("Error: "+response.data);
      }

    } catch (error) {
      console.error('Error:', error);
      setResult('Error:', error);
    }
    setLoading(false);
  };

  //Load template
  useEffect(() => {
    setError();
    setTemplate();
    fetchTemplate();
    setResult();
  }, [companyUrl, templateid]);

  //Update Page title
  useEffect(() => {
    let title;

    if (template?.templateName) {
      title = template.templateName;
    } else if (template?._id) {
      title = template._id;
    } else {
      title = 'Template';
    }
    
    if (title) {
      document.title = `${title} - ${companyUrl}`;
    } else {
      document.title = `${companyUrl}`;
    }

  }, [template]);

  //Preview
  useEffect(() => {
    let preview=template?.message?template.message:'';
    for(const [index, value] of fields.entries()){
      preview=preview.replace(`{{${index}}}`,value);
    }
    setPreview(preview);
  }, [fields]);

  const handleInputChange = (index, value) => {
    const newInputs = [...fields];
    newInputs[index] = value;
    setFields(newInputs);
  };

  const handleContactidChange = (value) => {
    setContactid(value);
  };

  if (error) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          <h4>Error: {error}</h4>
        </div>
      </div>
    );
    ;
  }

  if (!company || !template) {
    return (
      <div className="flex flex-col flex-auto grow h-full p-0 sm:p-6">
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">
          </div>
        </div>
    );
    ;
  }

  return (

      <div className={`${showMenu?`hidden`:`flex`} flex-col flex-auto grow h-full p-0 sm:p-6`}>
        <div className="flex flex-col flex-auto rounded-xl h-full bg-gray-100 p-0">


          <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100 m-4">
            <div className="ml-2 text-lg font-semibold">
              {template.templateName}
            </div>
            <div className="text-base text-gray-600 p-2 pl-6 text-sm">{template.message}</div>
            <div>

              <div className="ml-2 mt-4 flex flex-col">
                <label htmlFor="to" className="font-semibold">Destinatario:</label>
                <input type="text" id="contactid" name="contactid" placeholder="573111111111" onChange={(e) => handleContactidChange(e.target.value)}
                className="p-4 my-2 border rounded-xl focus:outline-none focus:border-indigo-300 h-10 shadow-inner"/>
              </div>

              {template?.fields?.map((field, index) => (
                <div className="ml-2 mt-4 flex flex-col" key={field.id}>
                  <label htmlFor={`field${field.id}`} className="font-semibold">{`{{`}{field.id}{`}}`}: {field.description}</label>
                  <input type="text" 
                  id={`field${field.id}`} 
                  name={field.id} 
                  placeholder={field.sample}
                  onChange={(e) => handleInputChange(field.id, e.target.value)}
                  className="p-4 my-2 border rounded-xl focus:outline-none focus:border-indigo-300 h-10 shadow-inner"/>
                </div>
              ))}

              {template?.fields?.length>0?
              <div className="ml-2 mt-4 flex flex-col">
                <div className="font-semibold">Vista previa:</div>
                <div
                  className="p-4 my-2 text-sm border rounded-xl focus:outline-none focus:border-indigo-300 shadow-inner text-gray-500"
                  >{preview}
                </div>
              </div>
              :<></>}

              <div className="ml-2 mt-4">
                <button
                  className="flex items-center justify-center bg-green-500 hover:bg-green-700 rounded-xl text-white px-4 py-1 flex-shrink-0"
                  id="sendButton"
                  onClick={handleSendTemplate}
                >
                  <span className="hidden sm:inline mr-2">Enviar</span>
                </button>
                <LoaderSymbol loading={loading} />
                
              </div>

              {result?
              <div className="ml-2 mt-4 flex flex-col">
                <div className="font-semibold">Respuesta</div>
                <div
                  className="p-4 my-2 text-sm border rounded-xl backgorund-gray-300 shadow-inner text-gray-800"
                  >{result}
                </div>
              </div>
              :<></>}

            </div>

          </div>

        </div>
      </div>
  );
};

export default Conversation;
