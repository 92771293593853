import React, { useEffect, useState , useRef} from "react";
import { useAuth } from "@clerk/clerk-react";
import { API_URL,PATH_SEND,CONFIG_TIME_CONVERSATION_WINDOW } from "./utils/constants";
import LoaderSymbol from "./icons/LoaderSymbol";

const ConversationTemplate = ({ company, contactid,handlerUpdate }) => {
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const [error, setError] = useState(null);
  const [showTemplateWindow, setShowTemplateWindow] = useState(false);
  const [templateid, setTemplateid] = useState();
  const [template, setTemplate] = useState();
  const [templates, setTemplates] = useState();
  const [result, setResult] = useState();
  const [preview, setPreview] = useState();
  const [fields, setFields] = useState([]);
  const { getToken } = useAuth(); // Get the token
  const templateWindow = useRef(null);

  // Fetch the conversations
  const fetchTemplates = async () => {
    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${company.data?.url}/templates`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTemplates(data);
    } catch (error) {
      setError(error.message);
    } finally {
      //setLoadingSearch(false);
    }
  };

  useEffect(() => {
    fetchTemplates()
  }, [company]);

  /**
   * Handle Send Template
   * @returns 
   */
  const handleSendTemplate = async () => {
    const resetConfirmation = window.confirm('Enviar mensaje?\n\n'+preview);
      if (!resetConfirmation) return;

    try {
      setIsLoading(true);
      const token = await getToken(); // Get the token
      let body={};
      fields?.map((value, index) => {
        if (index !== 0) {
          body[index] = value;
        }
      });
      const response = await fetch(`${API_URL}company/${company.data?.url}/template/${templateid}/send/${contactid}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      console.log(JSON.stringify(body));

      setIsLoading(false);

      if(response.status === 200){
        setResult(response.data);
        toggleShowTemplateWindow();
        handlerUpdate();
      }
      else{
        window.alert("Error: "+response.data);
        console.log("Error: "+response.data);
      }

    } catch (error) {
      window.alert("Error: "+error);
      console.error('Error:', error);
      setResult('Error:', error);
    }
  };

  const handleTemplateChange = (e) => {
    const { value } = e.target;
    let template=templates?.filter(template => template.templateId === value);
    if(template[0]){
      setTemplateid(value);
      setTemplate(template[0]);
      setPreview(template[0].message);
    }
    else{
      setTemplateid("");
      setTemplate(null);
      setPreview("");
    }
  };

  //Preview
  useEffect(() => {
    let preview=template?.message?template.message:'';
    for(const [index, value] of fields.entries()){
      preview=preview.replace(`{{${index}}}`,value);
    }
    setPreview(preview);
  }, [fields]);

  const handleInputChange = (index, value) => {
    const newInputs = [...fields];
    newInputs[index] = value;
    setFields(newInputs);
  };

  const toggleShowTemplateWindow = () => {
      if(showTemplateWindow){
        ///Close it, so reset template
        setTemplateid("");
        setTemplate(null);
        setPreview("");
      }
      setShowTemplateWindow(!showTemplateWindow);
      
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (templateWindow.current && !templateWindow.current.contains(event.target)) {
        setShowTemplateWindow(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-row flex-shrink-0 items-center h-16 rounded-b-xl bg-white w-full p-2 sm:p-4 shadow z-50 bottom-0" id="send-message">

      <div className="flex-grow ml-4">

      </div>

      <div className="relative inline-block">
        {showTemplateWindow && (
          <div className="absolute bottom-0 right-0 shadow-lg background-white rounded z-50" ref={templateWindow}>

            <div className="p-3 rounded-lg bg-white shadow rounded-xl w-100">

              {templates && Array.isArray(templates) && templates.length > 0?
              <select name="template" onChange={handleTemplateChange} 
              disabled={isLoading}
              className="mt-1 my-2 px-4 py-2 border rounded-xl focus:outline-none focus:border-indigo-300 shadow-inner">
                  <option value="" key={`0`}></option>
                {templates?.map((template) => (
                  <option value={template.templateId} key={template._id}>{template.templateName} ({template.templateId})</option>
                ))}
              </select>
              :
              <p>No tienes plantillas aprobadas</p>
              }
              
              {template ?
                    <div>

                      {template?.fields?.map((field, index) => (
                        <div className="ml-2 mt-4 flex flex-col" key={field.id}>
                          <label htmlFor={`field${field.id}`} className="font-semibold">{`{{`}{field.id}{`}}`}: {field.description}</label>
                          <input type="text"
                            id={`field${field.id}`}
                            name={field.id}
                            placeholder={field.sample}
                            onChange={(e) => handleInputChange(field.id, e.target.value)}
                            disabled={isLoading}
                            className="p-4 my-2 border rounded-xl focus:outline-none focus:border-indigo-300 h-10 shadow-inner" />
                        </div>
                      ))}

                      {template ?
                        <div className="ml-2 mt-4 flex flex-col">
                          <div className="font-semibold">Vista previa:</div>
                          <div
                            className="p-4 my-2 text-sm text-gray-500"
                          >{preview}
                          </div>
                        </div>
                        : <></>}

                      <div className="ml-2 mt-4 flex flex-row-reverse">
                        <button
                          className="flex items-center justify-center bg-green-500 hover:bg-green-700 rounded-xl text-white px-4 py-1 flex-shrink-0"
                          onClick={handleSendTemplate}
                          disabled={isLoading}
                        >
                          <span className="hidden sm:inline mr-2">Enviar</span>
                        </button>
                        <div><LoaderSymbol loading={isLoading} /></div>
                      </div>

                      {result ?
                        <div className="ml-2 mt-4 flex flex-col">
                          <div className="font-semibold">Respuesta</div>
                          <div
                            className="p-4 my-2 text-sm border rounded-xl backgorund-gray-300 shadow-inner text-gray-800"
                          >{result}
                          </div>
                        </div>
                        : <></>}

                    </div>

              :<></>
              }

                <div className="ml-2 mt-4 text-right">
                        <button
                          className="flex items-center justify-center bg-gray-500 hover:bg-gray-700 rounded-xl text-white px-4 py-1 flex-shrink-0"
                          onClick={toggleShowTemplateWindow}
                        >
                          <span className="hidden sm:inline mr-2">Cerrar</span>
                        </button>
                </div>

            </div>

          </div>

        )}
      </div>

      <div className="ml-4">
        <button
          className={`flex items-center justify-center ${(isLoading) ? 'bg-gray-500' : 'bg-green-500'}  hover:bg-green-700 rounded-xl text-white px-4 py-1 flex-shrink-0`}
          id="sendButton"
          onClick={toggleShowTemplateWindow}
        >
          <span className="hidden sm:inline mr-2">Enviar Plantilla</span>
          <span className="">
            <svg
              className="w-4 h-4 transform rotate-45 -mt-px"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              ></path>
            </svg>
          </span>
        </button>
      </div>
    </div>
  );

}

export default ConversationTemplate;
