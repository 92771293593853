import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { esES } from '@clerk/localizations'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

const PUBLISHABLE_KEY = process.env.REACT_APP_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/sign-in/" localization={esES}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/sign-in/*"
          element={<SignIn />}
        />
        <Route
          path="/sign-up/*"
          element={<SignUp />}
        />
        <Route
          path="*"
          element={
            <>
              <SignedIn>
                <App />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  </ClerkProvider>
);